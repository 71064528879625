@import url('https://fonts.googleapis.com/css2?family=League+Spartan:wght@400;600;700&display=swap');

* {
  box-sizing: border-box;
  margin: 0px;
  padding: 0px;
  text-decoration: none;
  /* text-transform: capitalize; */
  caret-color: transparent;
  scrollbar-width: 5px;
}
input:focus,textarea:focus{
  caret-color: black;
}
*::-webkit-scrollbar {
	width: 6px;
	background-color: #F5F5F5;
}
*::-webkit-scrollbar-thumb {
	background-color: #3C508B;
}
*::-webkit-scrollbar-track {
	-webkit-box-shadow: inset 0 0 6px "#FFEAE0";
	background-color: #FFEAE0;
}

body {
  background-color: white !important;
}


* {
  font-family: 'League Spartan', sans-serif;

}

body,
html,
#root {
  height: 100%;
  width: 100%;
}